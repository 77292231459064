import { formatDate } from '../../utils/date/date';
import { SmartMeterConsentDataRowWrapper } from './SmartMeterConsentDataRow.styles';

type SmartMeterConsentDataRowProps = {
  data: Array<Api.ISmartMeterDetail>;
};

export const SmartMeterConsentDataRow = (props: SmartMeterConsentDataRowProps) => {
  const smartMeterDetails = props.data?.map((item: Api.ISmartMeterDetail) => {
    return (
      <tr>
        <td>{item.meterSerialNumber}</td>
        <td>
          {item.configurationStartDate != null && item.configurationStartDate.length > 0
            ? formatDate(item.configurationStartDate)
            : ''}
        </td>
        <td>
          {item.configurationEndDate != null && item.configurationEndDate.length > 0
            ? formatDate(item.configurationEndDate)
            : ''}
        </td>
      </tr>
    );
  });

  return (
    <SmartMeterConsentDataRowWrapper>
      <table>
        <thead>
          <tr className='header'>
            <th>Meter serial number</th>
            <th>Smart meter start date</th>
            <th>Smart meter end date</th>
          </tr>
        </thead>
        <tbody>{smartMeterDetails}</tbody>
      </table>
    </SmartMeterConsentDataRowWrapper>
  );
};
