import { useEffect, useRef, useState } from 'react';

// Styles
import { ConsentGridWrapper, SmartMeterConsentWrapper } from './SmartMeterConsent.styles';

// Components
import { ButtonPrimary, ExpandableRow, Icon, Loading, NoResults, StyledTitle, Table } from '../../../common/components';
import { GridFilter } from '../../gridfilter/GridFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EssAccountsTypes } from '../../../types/account';
import { globalApiParams } from '../../../common/api/globals';
import { breakpoints } from '../../../common/styles/variables';
import { SmartMeterConsentDataRow } from './SmartMeterConsentDataRow';
import { iconsToUse } from '../../../common/styles/themes';
import { StatusBadge } from '../../../common/components/StatusBadge/StatusBadge';
import { ConsentModal } from '../../mymessages/modalTemplates/ConsentModal';
import { RefuseConsentModal } from '../../mymessages/modalTemplates/RefuseConsentModal';

import IcomoonReact from 'icomoon-react';
import iconSet from '../../../assets/selection.json';
import * as variables from '../../../common/styles/variables';

type SmartMeterConsentPageProps = {
  initialType: string;
  gridData: Array<Api.ISmartMeterConsent>;
  handlePagingChange: any;
  handleCustomerFilterChange: Function;
  customerData: Array<Common.IOption>;
  accountData: Array<Common.IOption>;
  siteData: Array<Common.IOption>;
  mpanMprnData: Array<Common.IOption>;
  isLoading?: boolean;
  hasMoreData: boolean;
  currentSortField: string;
  currentSortOrder: 'ASC' | 'DESC' | '';
  termsAndConditions: Array<HildebrandApi.RootObject>;
  handleConsentGiveClick: Function;
  handleConsentConfirm: Function;
  handleConsentRefuse: Function;
};

const defaultProps = {
  gridData: [],
  customerData: [],
  accountData: [],
  siteData: [],
  mpanMprnData: [],
  initialType: EssAccountsTypes.Electricity,
  isLoading: false,
  hasMoreData: true,
  currentSortField: '',
  currentSortOrder: 'ASC',
};

const SmartMeterConsentPage = (props: SmartMeterConsentPageProps) => {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [expandRow, setExpandRow] = useState<boolean>(false);
  const [smartMeterDetails, setSmartMeterDetails] = useState<Array<Api.ISmartMeterDetail>>([]);

  const [isAllSelected, setSelectAll] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<Array<number>>([]);
  const [isWarningIcon, setIsWarningIcon] = useState<boolean>(false);
  const [isLoadingEx, setIsLoadingEx] = useState(false);

  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showRefuseConsentModal, setShowRefuseConsentModal] = useState(false);
  const [consentItems, setConsentItems] = useState<Array<Api.ISmartMeterConsent>>([]);
  const [refuseConsentItems, setRefuseConsentItems] = useState<Array<Api.ISmartMeterConsent>>([]);

  const scrollableTable = useRef<any>();

  const sortDetails: Common.ISort = { field: props.currentSortField, order: props.currentSortOrder };

  const scrollToTop = () => {
    if (scrollableTable.current) {
      scrollableTable.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (props.gridData.length <= globalApiParams.pageSize) {
      scrollToTop();
    }

    props.gridData.map((item: Api.ISmartMeterConsent) => {
      if (item.consent === 'Not set' || item.consent === 'Expired') {
        item.warningIcon = true;
        setIsWarningIcon(true);
      }
    });
  }, [props.gridData]);

  useEffect(() => {
    if (props.termsAndConditions.length === 0) return;

    setShowConsentModal(true);
    setIsLoadingEx(false);

    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.termsAndConditions]);

  const handleCustomerFilterChange = (option: Common.IOption) => {
    clearSelection();
    props.handleCustomerFilterChange(option);
  };

  const handlePagingChange = () => {
    if (isAllSelected) {
      setSelectAll(!isAllSelected);
    }
    props.handlePagingChange();
  };

  const getConsentActions = (row: any, index: number) => (
    <div>
      <a href='/' onClick={e => toggleRow(e, index)} className='d-md-inline' style={{ marginLeft: '10px' }}>
        <IcomoonReact
          size={20}
          iconSet={iconSet}
          className='icon-chevron'
          icon={expandRow && selectedRow === index ? 'chevron-up' : 'chevron-down'}
        />
      </a>
    </div>
  );

  const headers: Array<Common.HeaderItem> = [
    {
      title: '',
      dataValue: 'warningIcon',
      enableSort: false,
    },
    {
      title: 'Consent',
      dataValue: 'consent',
      minWidth: 50,
      // responsive: {
      //   [breakpoints.mobile]: 140,
      // },
      enableSort: false,
    },
    {
      title: 'MPAN',
      dataValue: 'mpan',
      className: 'd-none d-md-table-cell',
      minWidth: 50,
      enableSort: false,
    },
    {
      title: 'Consent expiry',
      dataValue: 'consentExpiry',
      className: 'd-none d-md-table-cell',
      minWidth: 50,
      enableSort: false,
    },
    {
      title: 'Consent updated',
      dataValue: 'consentUpdated',
      className: 'd-none d-md-table-cell',
      minWidth: 50,
      enableSort: false,
    },
  ];

  const isChecked = (index: any) => {
    const foundIndex = checkedItems.findIndex((checkedIndex: number) => checkedIndex === index);
    return foundIndex > -1;
  };

  const onClickSort = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
  };

  const handleMessageSelect = (event: any, row: number | null) => {
    const wrapper = event.target.closest('td')?.classList?.contains?.('select-item'); //!!
    if (wrapper) {
      return;
    }
    if (row !== undefined) {
      setSelectedRow(row);
      setExpandRow(false);
    }
  };

  const clearSelection = () => {
    setSelectedRow(null);
    setExpandRow(false);
    setSelectAll(false);
    setCheckedItems([]);
  };

  const getIndexesOfEnabledCheckboxes = (): Array<number> => {
    // Gets list of Indexes for the checkboxes that aren't readOnly
    const items: Array<number> = props.gridData
      .map((item: Api.ISmartMeterConsent, index: number) => ({ item, index }))
      .filter(({ item }) => item.readOnly === false)
      .map(({ index }) => index);

    return items;
  };

  const getEnabledCheckboxesCount = (): number => {
    const count = props.gridData.filter((item: Api.ISmartMeterConsent) => item.readOnly === false).length;
    return count;
  };

  const handleItemChange = (index: number) => {
    setSelectedRow(null);

    const items = checkedItems.slice(0, checkedItems.length);

    const foundIndex = items.findIndex((checkedIndex: number) => checkedIndex === index);

    if (foundIndex > -1) {
      items.splice(foundIndex, 1);
    } else {
      items.push(index);
    }

    const enabledCheckboxesCount = getEnabledCheckboxesCount();
    if (items.length === enabledCheckboxesCount) {
      handleSelectAll();
    } else if (isAllSelected) {
      setSelectAll(false);
    }

    setCheckedItems(items);
  };

  const handleSelectAll = () => {
    setSelectedRow(null);

    const select = !isAllSelected;
    setSelectAll(select);
    if (select) {
      const listOfCounts: Array<number> = getIndexesOfEnabledCheckboxes();
      setCheckedItems(listOfCounts);
    } else {
      // unselect
      setCheckedItems([]);
    }
  };

  const actionStyles = {
    width: '150px',
    opacity: '1',
  };

  const getSelectedItems = (): Array<Api.ISmartMeterConsent> => {
    var selectedItems: Array<any> = [];
    checkedItems.map((checkedItem: number) => {
      const item = props.gridData[checkedItem];
      selectedItems.push(item);
    });
    return selectedItems;
  };

  const SmartMeterConsentFilterAdditionalItems = () => {
    const additionalItems = [
      <ButtonPrimary
        key='consentPageAdditionalItem1'
        title={'Give consent'}
        disabled={checkedItems.length <= 0 || checkedItems.length > 900}
        className={'additional-buttons additional-buttons-spacing mr-4 download-btn'}
        onClick={(event: any) => {
          event.preventDefault();
          const items: Array<Api.ISmartMeterConsent> = getSelectedItems();
          setConsentItems(items);
          setIsLoadingEx(true);
          props.handleConsentGiveClick();
        }}
      />,
      <ButtonPrimary
        key='consentPageAdditionalItem2'
        title={'Refuse consent'}
        disabled={checkedItems.length <= 0 || checkedItems.length > 900}
        className={'additional-buttons-spacing download-btn'}
        onClick={(event: any) => {
          event.preventDefault();
          const items: Array<Api.ISmartMeterConsent> = getSelectedItems();
          setRefuseConsentItems(items);
          setShowRefuseConsentModal(true);
        }}
      />,
    ];

    return additionalItems;
  };

  const toggleRow = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, rowIndex: number) => {
    event.preventDefault();
    if (!expandRow) {
      setIsLoadingEx(true);

      const row: Api.ISmartMeterConsent = props.gridData && props.gridData[rowIndex];
      const data = row.meters;

      setSmartMeterDetails(data);

      setIsLoadingEx(false);
    }
    setExpandRow(!expandRow);
  };

  const getExpandableRow = (row: any, rowIndex: number) => (
    <ExpandableRow className={`sub-row ${selectedRow === rowIndex ? 'selected' : ''} ${expandRow ? 'open' : ''}`}>
      <div style={{ padding: '0 0', borderTop: '1px solid black' }}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr className='expandable-content'>
              <SmartMeterConsentDataRow data={smartMeterDetails}></SmartMeterConsentDataRow>
            </tr>
          </tbody>
        </table>
      </div>
    </ExpandableRow>
  );

  const renderWarningIcon = (value: any) => (
    <td className='warningIcon'>{value ? <Icon icon={iconsToUse.warning} size={variables.iconSizeSmall} /> : ''}</td>
  );

  const handleStatusClick = (item: any) => {
    // Do nothing, it's a badge not a button
  };

  const renderconsent = (value: string, row: any) => {
    return (
      <td className='d-md-table-cell pb-0'>
        <StatusBadge
          className={'statusBadge'}
          title={value}
          borderRadiusType={'none'}
          notSetStatus={value === 'Not set'}
          expiredStatus={value === 'Expired'}
          yesStatus={value === 'Yes'}
          noStatus={value === 'No'}
          onClick={() => handleStatusClick(row)}
        />
      </td>
    );
  };

  const handleModalClose = () => {
    setShowConsentModal(false);
    setShowRefuseConsentModal(false);
    clearSelection();
  };

  const onConsentConfirm = (tcId: string, reference: string) => {
    props.handleConsentConfirm(tcId, reference, consentItems);
    handleModalClose();
    setConsentItems([]);
  };

  const onRefuseConsentConfirm = () => {
    props.handleConsentRefuse(refuseConsentItems);
    handleModalClose();
    setRefuseConsentItems([]);
  };

  return (
    <SmartMeterConsentWrapper>
      <StyledTitle>Smart meter consent</StyledTitle>
      <div className='d-flex-column'>
        <div className='my-3'>
          <p>
            In order to view your smart meter half-hourly data, we require you to give consent for us to obtain the data
            from a 3rd party supplier.
          </p>
          <p>Select the MPAN using the check box and click the relevant update button.</p>
        </div>
      </div>
      <GridFilter
        showNhhAndMixed={true}
        showClearAll={false}
        showSites={false}
        showAccount={false}
        handleAccountFilterChange={() => {}}
        handleCustomerFilterChange={handleCustomerFilterChange}
        handleClearAllClick={() => {}}
        showConsentReminder={isWarningIcon}
        renderAdditionalListItems={SmartMeterConsentFilterAdditionalItems}
      />

      {!props.isLoading && props.gridData.length === 0 && <NoResults />}
      <ConsentGridWrapper>
        {props.gridData.length > 0 && (
          <>
            <InfiniteScroll
              dataLength={props.gridData.length}
              next={handlePagingChange}
              hasMore={props.hasMoreData}
              loader={''}
              scrollableTarget='scrollableDiv'
              scrollThreshold={0.9}
            >
              <div
                id='scrollableDiv'
                ref={scrollableTable}
                className='table-responsive table-fixed fixed-column columns-1'
              >
                <Table
                  isSelectable={true}
                  headers={headers}
                  actions={getConsentActions}
                  rows={props.gridData}
                  isChecked={isChecked}
                  sortDetails={sortDetails}
                  onClickSort={onClickSort}
                  isRowExpanded={expandRow}
                  actionStyles={actionStyles}
                  isAllSelected={isAllSelected}
                  selectedRowIndex={selectedRow}
                  expandableRow={getExpandableRow}
                  handleSelectAll={handleSelectAll}
                  handleCheckRow={handleItemChange}
                  handleSelectRow={handleMessageSelect}
                  className='list table plain text-left'
                  renderwarningIcon={renderWarningIcon}
                  renderconsent={renderconsent}
                />
              </div>
            </InfiniteScroll>
          </>
        )}

        {(props.isLoading || isLoadingEx) && <Loading overlay nowait />}
      </ConsentGridWrapper>

      {showConsentModal && (
        <ConsentModal
          show={showConsentModal}
          onConfirm={onConsentConfirm}
          onClose={handleModalClose}
          termsAndConditions={props.termsAndConditions}
        />
      )}
      {showRefuseConsentModal && (
        <RefuseConsentModal
          show={showRefuseConsentModal}
          onConfirm={onRefuseConsentConfirm}
          onClose={handleModalClose}
        />
      )}
    </SmartMeterConsentWrapper>
  );
};

SmartMeterConsentPage.defaultProps = defaultProps;

export default SmartMeterConsentPage;
